export const props = () => ({
  zendeskId: "cab743de-0681-4851-9630-98da2a6e3d80",
  apiUrl: "https://api-jatoba-homolog.graodireto.com.br",
  logo: "graodireto",
  appTitle: "Gestão de Contratos",
  theme: "graodireto",
  companyLanguage: "default",
  companyName: "Jatoba",
  keyCloakConfig: {
    url: "https://auth-homolog.graodireto.com.br",
    realm: "jatoba",
    clientId: "jatoba-front",
  },
  production: false,
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  hotjarConfig: "",
});
